<template>
    <div>
        <b-modal
            id="modal-change-status"
            no-close-on-backdrop
            ok-only
            :ok-title="i18nT(`Change Status`)"
            :title="i18nT(`Change Status`)"
            @ok="validationChangeStatus"
        >
            <validation-observer ref="changeStatusForm">
                <b-form  v-if="candidateDetails[this.applicationKey] && candidateDetails[this.applicationKey].Id" class="mt-2">

                    <b-row>
                        <b-col sm="12">

                            <!-- HIRING INPUTS -->
                            <b-row
                                v-if="targetStage.type == 'HIRED'"
                                class="mb-2"
                            >
                                <b-col md="6">
                                    <b-form-group
                                        :label="i18nT(`Hiring date`)" class="required"
                                        label-for="message"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Hiring date"
                                            rules="required"
                                        >
                                            <date-picker
                                                v-model="hiringDate"
                                                placeholder=""
                                                reset-button
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group
                                        :label="i18nT(`Evaluation Period End`)" class="required"
                                        label-for="message"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            :name="i18nT(`Evaluation Period End`)"
                                            rules="required"
                                        >
                                            <date-picker
                                                v-model="evaluationPeriodEnd"
                                                placeholder=""
                                                reset-button
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group
                                        :label="i18nT(`Ending`)"
                                        label-for="message"
                                    >
                                        <date-picker
                                            v-model="ending"
                                            placeholder=""
                                            reset-button
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group
                                        :label="i18nT(`Net salary`)"
                                        label-for="message"
                                    >
                                        <b-form-input
                                            v-model="salaryNet"
                                            placeholder=""
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group
                                        :label="i18nT(`Gross salary`)"
                                        label-for="message"
                                    >
                                        <b-form-input
                                            v-model="salaryGross"
                                            placeholder=""
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group
                                        :label="i18nT(`Type`)"
                                        label-for="message"
                                    >
                                        <b-form-select
                                            v-model="salaryType"
                                            :options="salaryTypes"
                                            placeholder=""
                                        />
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <!-- BASE INPUTS -->
                            <b-row>
                                <b-col cols="12">
                                    <b-form-group
                                        :label="i18nT(`Assign application to stage`)"
                                        class="required"
                                        label-for="stageLabel"
                                    >
                                        <v-select
                                            v-model="localStageLabel"
                                            :reduce="(val) => val.short"
                                            :options="actionStages"
                                            :placeholder="i18nT(`Select stage`)"
											:clearable="false"
                                            label="label"
                                            @input="selectStage"></v-select>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" v-if="localStageLabel">
                                    <b-form-group v-if="targetStage.message_templates">
                                        <b-form-checkbox
                                            v-model="changeStatusNotification"
                                            :checked="changeStatusNotification"
                                            switch
                                        >
                                            {{ i18nT(`Send notification email?`) }}
                                        </b-form-checkbox>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-row
                                v-if="changeStatusNotification && targetStage.type == 'INTERVIEW'"
                                class="mb-2"
                            >
                                <b-col cols="12">
                                    <b-form-group
                                        :label="i18nT(`Select interview`)"
                                    >
                                        <v-select
                                            v-model="selectedInterview"
                                            :options="availableInterviews"
                                            label="text"
                                        />
                                    </b-form-group>

                                    <span class="text-muted font-small-2">{{i18nT(`Is there no scheduled interview with this candidate yet?`)}} </span><span class="text-primary font-small-2 cursor-pointer" @click="openAddInterview"> {{i18nT(` Schedule an interview.`)}}</span>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col cols="12">
                                    <b-form-group
                                        v-if="changeStatusNotification && targetStage.message_templates"
                                        :label="i18nT(`Message Template`)"
                                        class="required"
                                        label-for="messageTemplate"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Message Template"
                                            rules="required"
                                        >
                                            <v-select
                                                v-model="statusChangeTemplate"
                                                :options="statusTemplateOptions"
                                                :state="errors.length > 0 ? false : null"
                                                label="text"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>

                                        <b-row class="pt-2">
                                            <b-col cols="12">
                                                <b-form-group
                                                    :label="i18nT(`Subject`)"
                                                    label-for="emailSubject">
                                                    <b-form-input
                                                        name="emailSubject"
                                                        type="text" v-model="statusChangeSubject"></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="12">
                                                <b-form-group
                                                    :label="i18nT(`Email Message`)"
                                                    label-for="emailMessage">
                                                    <b-form-textarea
                                                        name="emailMessage"
                                                        v-model="statusChangeMessage"
                                                        :placeholder="i18nT(`Enter message`)"
                                                        rows="8">

                                                    </b-form-textarea>
                                                </b-form-group>

                                            </b-col>
                                            <b-col cols="12">
                                                <p class="font-small-3 text-muted">{{i18nT(`You can use the following placeholder tags which will be replaced by the actual data in the e-mail`)}}:</p>
                                                <p class="font-small-3 text-muted">
                                                    [candidate_first_name], [candidate_last_name], [candidate_full_name], [candidate_email],
                                                    [position_title], [position_url], [company_name], [employee_first_name], [employee_last_name], [employee_full_name], [employee_email], [interview_details]
                                                </p>
                                            </b-col>
                                        </b-row>

                                    </b-form-group>
                                </b-col>
                            </b-row>

                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>
        <!-- ADD INTERVIEW MODAL -->
        <b-modal
            id="modal-add-interview"
            no-close-on-backdrop
            hide-footer
        >
            <interview-add-form
                :formId="'interviewForm'"
                :candidateDetails="localCandidateDetails"
                @added="interviewForModal"></interview-add-form>
        </b-modal>
    </div>

</template>

<script>

import {
    BCol,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BModal,
    BRow
} from "bootstrap-vue";
import vSelect from "vue-select";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import InterviewAddForm from "@/views/recruiting/Candidates/InterviewAddForm.vue";
import datePicker from "@core/components/DatePicker.vue";

export default {
    components: {
        InterviewAddForm,
        BModal,
        BForm,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BFormCheckbox,
        BFormTextarea,
        BRow,
        BCol,
        datePicker,
        ValidationProvider,
        ValidationObserver,
        vSelect
    },
    props: {
        stageLabel: {
            type: String,
            default: null,
        },
        candidateDetails: {
            type: Object,
            default: () => {
            }
        },
    },
    data() {
        return {
            hiringDate: null,
            evaluationPeriodEnd: null,
            ending: null,
            salaryNet: null,
            salaryGross: null,
            salaryType: null,
            changeStatusNotification: false,
            statusChangeTemplate: null,
            statusChangeSubject: null,
            statusChangeMessage: null,


            actionStages: [],
            targetStage: {
                Id: null,
                Name: null,
                type: null,
                short: null,
                message_templates: [],
            },

            localStageLabel: this.stageLabel,
            localCandidateDetails: this.candidateDetails,

            selectedInterview: null,
            availableInterviews: [],

            statusTemplateOptions: [],

            salaryTypes: [
                {value: 'gross', text: 'Gross'},
                {value: 'net', text: 'Net'},
            ],

        }
    },
    methods: {
        refresh() {
            if(this.localCandidateDetails.tabs && this.localCandidateDetails.tabs.interviews){
                let defaultItem = this.candidateDetails.tabs.interviews.data[0];
                this.selectedInterview = {
                    value: defaultItem.Id,
                    text: defaultItem.Location + ' - ' + this.formatDate(defaultItem.StartDate)+' '+this.i18nT(`at`)+' '+this.formatTime(defaultItem.StartDate)
                };
            }
        },
        interviewForModal(){
            this.refresh();
            this.$bvModal.hide('modal-add-interview');
        },
        openAddInterview(){
            this.$bvModal.show('modal-add-interview');
        },
        validationChangeStatus(event) {

            event.preventDefault()

            this.$refs.changeStatusForm.validate().then(success => {
                if (success) {
                    const formData = new FormData()
                    formData.append(
                        'application_id',
                        this.candidateDetails[this.applicationKey].Id
                    )

                    formData.append('stage', this.targetStage.short)
                    formData.append('stage_type', this.targetStage.type)

                    if (this.changeStatusNotification) {
                        formData.append('message', this.statusChangeMessage)
                        formData.append('subject', this.statusChangeSubject)
                    }

                    if(this.targetStage.short == 'INTERVIEW' && this.changeStatusNotification && this.selectedInterview){
                        formData.append('interview_id', this.selectedInterview.value)
                    }

                    if (this.targetStage.type === 'HIRED') {
                        formData.append('onboarding_at', this.hiringDate)
                        formData.append('evaluation_period_end', this.evaluationPeriodEnd)
                        formData.append('ending_at', this.ending)
                        formData.append('salary_net', this.salaryNet)
                        formData.append('salary_gross', this.salaryGross)
                        formData.append('salary_type', this.salaryType)
                    }

                    this.$http
                        .post('candidates/changeStage', formData)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.$bvModal.hide('modal-change-status')
                            this.refresh()
                            this.hiringDate = null
                            this.evaluationPeriodEnd = null
                            this.ending = null
                            this.salaryNet = null
                            this.salaryGross = null
                            this.salaryType = null

                            this.$emit('changed');
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
                else {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.i18nT(`Please fill all required fields.`),
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    })

                }
            })
        },
        selectStage(stageLabel){
            console.log("Selecting stage: ", stageLabel)
            console.log("Action stages: ", this.actionStages);
            this.targetStage = this.actionStages.find(stage => stage.short === stageLabel)
            console.log("Picked target stage: ", this.targetStage);
        },
    },
    mounted() {
        this.$http.get(`candidates/stages`).then(({data}) => {
            let self = this;

            if (data.data) {
                data.data.forEach(function (oneStage) {
                    if (oneStage.short !== '' && oneStage.short !== 'SOURCED') {
                        self.actionStages.push(oneStage)
                    }
                })
            }

            // this.actionStages.shift()

        })
    },
    watch: {
        availableInterviews: {
            deep: true,
            handler(newInterviews) {
                if (newInterviews.length > 0) {
                    this.selectedInterview = newInterviews[newInterviews.length - 1];
                }
            }
        },
        stageLabel: {
            handler: function (newStageLabel) {
              this.localStageLabel = newStageLabel;
            },
            immediate: true
        },
        candidateDetails: {
            handler: function (newCandidateDetails) {
                this.localCandidateDetails = newCandidateDetails;
            },
            immediate: true
        },
        localStageLabel: {
            handler: function (newStageLabel) {
                this.selectStage(newStageLabel)
            },
        },
        targetStage: {
            handler: function (newTargetStage) {
                if(newTargetStage){

                    this.statusTemplateOptions =
                        newTargetStage.message_templates.length > 0
                            ? newTargetStage.message_templates.map(e => (
                                {
                                    value: e.id,
                                    text: e.label,
                                    message: e.message,
                                    subject: e.subject
                                }
                            ))
                            : []

                    if(this.statusTemplateOptions.length > 0){
                        this.statusChangeTemplate = this.statusTemplateOptions[0];
                    }

                    if(this.statusChangeTemplate){
                        this.statusChangeSubject = this.statusChangeTemplate.subject;
                        this.statusChangeMessage = this.statusChangeTemplate.message;
                    }
                }

            },
        },
    },
    computed: {
        applicationKey: function () {
            return this.candidateDetails.application ? 'application' : 'current_application';
        },
        // statusTemplateOptions() {
        //     return this.targetStage.message_templates.map((template) => {
        //         return {
        //             value: template.Id,
        //             text: template.Subject,
        //         }
        //     })
        // },
    }
}

</script>

<style scoped lang="scss">

</style>
